<template>
  <el-dialog
    title="确认通过"
    :visible="dialogShow"
    width="30%"
    :before-close="handleClose"
    append-to-body
  >
    <div class="content">
      <el-row>
        <el-col :span="12">
          <div class="col-inner mar-bot20">
            客户昵称: {{rowData.userName}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="col-inner mar-bot20">
            入金账号: {{rowData.login}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="col-inner mar-bot20">
            活动名称: {{rowData.ruleName}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="col-inner mar-bot20">
            赠金类型: {{getType(rowData.giveType)}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="col-inner mar-bot20">
            入金数量: ${{rowData.goldEntry}}
          </div>
        </el-col>
        <el-col>
          <div class="col-inner mar-bot20">
            <el-col :span="4">
              赠金数量:
            </el-col>
            <el-col :span="20">
              <div class="input-box pr mar-bot20">
                <el-input v-model="selfMoneyNumber">
                </el-input>
                <span class="sufix pa">$</span>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col class="mar-bot20">
          <div class="col-inner">
            <el-col :span="4">
              达标交易手数:
            </el-col>
            <el-col :span="20">
              <div class="input-box pr">
                <el-input v-model="selfSuccessNumber"></el-input>
                <span class="sufix pa">手</span>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col>
          <div class="col-inner mar-bot20">
            <el-col :span="4">
              活动账号:
            </el-col>
            <el-col :span="20">
              <el-select
                style="width: 100%"
                v-model="activeAccount"
              >
                <el-option
                  v-for="item in accountList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="cancleHandle">取 消</el-button>
    <el-button :loading="butLoading" type="primary" @click="confirmHandle">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
  import { twoDecimal } from '@/utils/validate'
  import { updateGiveGoldStatus, getAccountList } from '@/api/bussetup/withGold'
  export default {
    name: 'confirmDialog',
    props: {
      dialogShow: {
        type: Boolean,
        default: false
      },
      rowData:{
        type: Object,
        default(){
          return {
            moneyNumber: 0,
            successNumber: 0,
            giveType: '',
          }
        }
      }
    },
    data() {
      return {
        selfMoneyNumber: this.rowData.moneyNumber,
        selfSuccessNumber: this.rowData.successNumber && this.rowData.successNumber / 100,
        butLoading: false,
        activeAccount: '',
        accountList: []
      }
    },
    watch:{
      rowData:{
        handler(newValue){
          this.selfMoneyNumber = newValue.moneyNumber;
          this.selfSuccessNumber = newValue.successNumber / 100;
          this.activeAccount = newValue.login;

        },
        deep: true,
      },
      dialogShow(newValue){
        if(newValue){
          this.getAccountList();
        }
      }
    },
    methods: {
      getAccountList(){
        getAccountList({ custId: this.rowData.custId }).then((res) => {
          this.accountList = res;
        })
      },
      close(){
        this.$emit('close');
      },
      handleClose() {
        this.close();
      },
      cancleHandle(){
        this.close();
      },
      getType(giveType){
        return giveType == 1 ? '信用额 ' : '余额'
      },
      confirmHandle(){
        if(this.selfSuccessNumber === '' || this.selfSuccessNumber === null){
          this.$message({
            type: 'warning',
            message: '请输入达标交易手数'
          })
          return;
        }
        if(this.selfSuccessNumber != 0 && !twoDecimal(this.selfSuccessNumber)){
          this.$message({
            type: 'warning',
            message: '达标交易手数最多两位小数'
          })
          return;
        }
        this.butLoading = true;

        updateGiveGoldStatus({
          id: this.rowData.id,
          login: this.activeAccount,
          moneyNumber: this.selfMoneyNumber,
          successNumber: this.selfSuccessNumber,
          status: 1,
        }).then((res) => {
          this.butLoading = false;
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.$emit('pass-success');
        }).catch((error) => {
          this.butLoading = false;
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.content {
  .sufix {
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
</style>
