<template>
  <div class="app-container summary-page">
    <eHeader
      @clear="clearHeaderQuery"
      :query="query"
      @toQuery="toQuery"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="userName" label="客户姓名"/>
      <el-table-column prop="depositLogin" label="入金账号"/>
      <el-table-column prop="login" label="活动账号"/>
      <el-table-column prop="ruleName" label="活动名称"/>
      <el-table-column prop="giveType" label="赠金类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row.giveType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="moneyNumber" label="赠金数量($)"/>
      <el-table-column prop="goldEntry" label="入金数量($)"/>
      <el-table-column prop="successNumber" label="达标交易手数">
        <template slot-scope="scope">
          <span>{{getSuccessNumber(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="completeNumber" label="完成交易手数">
        <template slot-scope="scope">
          <span>{{getCompleteNumber(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="goldEntryTime" with="120px" label="入金时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.goldEntryTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="endTime" label="到期时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.endTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="MT4" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ADMIN','TCustBonusAct_EDIT']) && scope.row.status == 0"
            type="danger"
            size="mini"
            @click="rejectHandle(scope.row.id)"
          >拒绝</el-button>
          <el-button
            v-if="getCancleFlag(scope.row)"
            type="danger"
            size="mini"
            @click="cancleHandle(scope.row.id)"
          >取消</el-button>
          <el-button
            v-if="checkPermission(['ADMIN','TCustBonusAct_EDIT']) && scope.row.status == 5 "
            size="mini"
            @click="cancleFreezeHandle(scope.row.id)"
          >取消冻结</el-button>
          <el-button
            v-if="checkPermission(['ADMIN','TCustBonusAct_EDIT']) && scope.row.status == 0"
            type="danger"
            size="mini"
            @click="passHandle(scope.row)"
          >通过</el-button>
          <el-button
            v-if="checkPermission(['ADMIN','TCustBonusAct_EDIT','TCustBonusAct_ALL']) && scope.row.status == 6"
            type="primary"
            size="mini"
            @click="applyTransferHandle(scope.row)"
          >申请转出</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <confirm-dialog
      :dialog-show="dialogShow"
      @close="closeHandle"
      @pass-success="passSuccessHandle"
      :row-data="currentWithgoldData"
    ></confirm-dialog>
  </div>
</template>

<script>
import confirmDialog from '@/components/finance/withgold/confirmDialog'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { updateGiveGoldStatus,applyTranferd } from '@/api/bussetup/withGold'

import eHeader from '@/components/finance/withgold/header'
import { parseWithdrawStatus,parseCurrencyType ,parseOutType,parsePayStatus} from '@/views/pt/ib/utils/index'
export default {
  name:'withgold',
  components: { eHeader, confirmDialog },
  mixins: [initData],
  data() {
    return {
      loading: false,
      sup_this: this,
      dialogShow: false,
      currentWithgoldData: {},
      statistics:{
        totalMoneyNumber: '',
        totalGoldEntry: '',
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseWithdrawStatus,
    parseOutType,
    parseCurrencyType,
    parsePayStatus,
    beforeInit() {
      this.url = '/crm/queryTCustBonusActs'
      this.params = { page: this.page, size: this.size}
      const query = this.query
      const likeName = query.likeName;
      const status = query.status;
      const startTime = query.startTime;
      const endTime = query.endTime;
      likeName && (this.params.likeName = likeName);
      status && (this.params.status = status);
      if(status === 0){
        this.params.status = status
      }
      startTime && (this.params.startTime = this.parseTime(startTime));
      endTime && (this.params.endTime = this.parseTime(endTime));
      return true
    },
    getSuccessNumber(row){
      if(row.giveType == 2){
        return '---'
      }else {
        return row.successNumber / 100;
      }
    },
    getCompleteNumber(row){
      if(row.giveType == 2){
        return '---'
      }else {
        return row.completeNumber && row.completeNumber / 100;
      }
    },
    rejectHandle(id){
      this.$confirm(`是否拒绝该账户赠金申请`,'确认拒绝', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        updateGiveGoldStatus({
          id: id,
          status: 0,
        }).then((res) => {
          this.init();
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    cancleHandle(id){
      this.$confirm(`是否确认取消该笔信用额?`,'确认取消', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        updateGiveGoldStatus({
          id: id,
          status: 7,
        }).then((res) => {
          this.init();
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    cancleFreezeHandle(id){
      this.$confirm(`确认账号已入金后方可取消冻结?`,'确认取消冻结', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        updateGiveGoldStatus({
          id: id,
          status: 3,
        }).then((res) => {
          this.init();
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    passHandle(row){
      this.currentWithgoldData = row;
      this.dialogShow = true;
    },
    clearHeaderQuery(){
      this.query.likeName = '';
      this.query.status = '';
      this.query.startTime = '';
      this.query.endTime = '';
      this.toQuery();
    },
    getType(giveType){
      return giveType == 1 ? '信用额 ' : '余额'
    },
    getStatus(status){
      /*0-待审核 1-已结束 2-已拒绝 3-进行中 4-已过期 5-已冻结 6-已完成 7-已取消 8-转出审核中*/
      let text = '';
      switch (status) {
        case 0:
          text = '待审核';
          break;
        case 1:
          text = '已结束';
          break;
        case 2:
          text = '已拒绝';
          break;
        case 3:
          text = '进行中';
          break;
        case 4:
          text = '已过期';
          break;
        case 5:
          text = '已冻结';
          break;
        case 6:
          text = '已完成';
          break;
        case 7:
          text = '已取消';
          break;
        case 8:
          text = '转出审核中';
          break;
      }
      return text;
    },
    closeHandle(){
      this.dialogShow = false;
    },
    passSuccessHandle(){
      this.dialogShow = false;
      this.init();
    },
    getCancleFlag(row){
      return checkPermission(['ADMIN','TCustBonusAct_EDIT']) && (row.status == 3 || row.status == 6);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 6:
            sums[index] = `${this.statistics.totalMoneyNumber}`;
            break;
          case 7:
            sums[index] = `${this.statistics.totalGoldEntry}`;
            break;
        }
      });

      return sums;
    },
    applyTransferHandle(row){
      this.$confirm(`确认申请转出`,'注意', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        //点击确定的操作(调用接口)
        applyTranferd({
          id: row.id,
        }).then((res) => {
          this.init();
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    }
  }
}
</script>

<style scoped>

</style>
